import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { CreateCustomerTenantModel, CreatePartnerTenantModel } from '../models/dtos/createTenantModel';
import { AcronisTenant } from '../models/domain/acronisTenant';
import { Store } from '@ngxs/store';
import { CustomerState } from '../../customer/state/customer.state';
import { AvailableBackendAndEdition } from '../state/tenant/tenant.state';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly acronisTenantApi: string = environment.acronisApiUrl + '/Tenants';
  private readonly acronisUsersApi: string = environment.acronisApiUrl + '/Users';

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  public get(tenantId: string, partnerId: any): Observable<AcronisTenant> {
    // tjek
    return this.http.get<AcronisTenant>(`${this.acronisTenantApi}/${tenantId}`, {
      params: {
        partnerId,
      },
    });
  }

  public getChildren(tenantId: string, partnerId: any): Observable<{ tenants: AcronisTenant[]; writeAccess: boolean }> {
    // tjek
    return this.http.get<{ tenants: AcronisTenant[]; writeAccess: boolean }>(`${this.acronisTenantApi}/${tenantId}/Children`, {
      params: {
        partnerId,
      },
    });
  }

  public post(tenant: CreateCustomerTenantModel, partnerId: any): Observable<any> {
    // tjek
    return this.http.post<AcronisTenant>(`${this.acronisTenantApi}`, tenant, {
      params: {
        partnerId,
        WithContent: '' + true,
      },
    });
  }

  public createPartnerAcronisTenant(tenant: CreatePartnerTenantModel, id: number): Observable<AcronisTenant> {
    return this.http.post<AcronisTenant>(`${this.acronisTenantApi}/asPartner`, tenant, {
      params: {
        partnerId: '' + id,
        WithContent: '' + true,
      },
    });
  }

  public attachAcronisTenantIdToPartner(partnerId: number, tenantId: string): Observable<any> {
    return this.http.post(`${this.acronisTenantApi}/AssignToPartner`, {
      partnerId,
      tenantId,
    });
  }

  public putToProduction(tenantId: string, partnerId: any): Observable<any> {
    return this.http.patch<AcronisTenant[]>(
      `${this.acronisTenantApi}/${tenantId}/mode`,
      {},
      {
        params: {
          partnerId,
        },
      },
    );
  }

  public getAvailableSubInfo(tenantId: string, partnerId: any): Observable<AvailableBackendAndEdition> {
    return this.http.get<AvailableBackendAndEdition>(`${this.acronisTenantApi}/${tenantId}/AvailableEditionsAndBackendTypes`, {
      params: {
        partnerId,
      },
    });
  }

  public checkUserName(userName: string, partnerId: any): Observable<AvailableBackendAndEdition> {
    return this.http.get<AvailableBackendAndEdition>(`${this.acronisUsersApi}/Check/${userName}`, {
      params: {
        partnerId,
      },
    });
  }
}
