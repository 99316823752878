export enum ExternalServicesKey {
  Microsoft = 'MICROSOFT',
  Dropbox = 'DROPBOX',
  DropboxSign = 'HELLOSIGN',
  Acronis = 'ACRONIS',
  Eset = 'ESET',
  Exclaimer = 'EXCLAIMER',
  KeepIt = 'KEEPIT',
}

export interface IExternalServices {
  [key: string]: string;
}
