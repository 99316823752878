import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, createSelector, NgxsOnInit } from '@ngxs/store';
import { PartnerService } from '../services/partner.service';
import {
  AttachAcronisTenantIdToPartner,
  CreatePartnerAcronisTenant,
  GetAllPartners,
  GetPartner,
  GetPartnerInvoices,
  GetPartnerLogo,
  GetPartners,
  GetPartnersSelf,
  GetPartnerTOS,
  ResetPartnerInvoices,
  SelectPartner,
  UpdatePartner,
  UpdatePartnerLogo,
  UpdatePartnerTOS,
} from './partner.actions';
import { produce } from 'immer';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { IGetPartner, IPartner, IPartnerFilter } from '../models/partner.model';
import { IMetadata } from '../../shared/models/shared.model';
import { ToastrService } from 'ngx-toastr';
import { TenantService } from 'src/modules/acronis/services/tenant.service';
import { AuthServiceOld } from 'src/authentication/AuthService/auth.service';
import { Invoice } from '../models/invoice.model';
import { ExternalServicesKey } from 'src/modules/shared/models/external-services.model';

export class PartnerStateModel {
  partnersMetadata: IMetadata;
  partners: IPartner[]; //used in the main partner page (GetPartners Action)
  allPartners: IPartner[]; //used for getting all existing partners (GetAllPartners Action)
  partnerSelf: IPartner; //returns the partner object of the authenticated user
  selectedPartner: IPartner; //used for partner-overview page
  arePartnersLoading: boolean;
  areAllPartnersLoading: boolean;
  invoicesList: Invoice[];
  isInvoicesLoading: boolean;
}

const defaults = {
  partners: [],
  allPartners: [],
  selectedPartner: null,
  partnerSelf: null,
  partnersMetadata: null,
  arePartnersLoading: true,
  areAllPartnersLoading: true,
  invoicesList: [],
  isInvoicesLoading: true,
};

@State<PartnerStateModel>({
  name: 'partner',
  defaults,
})
@Injectable()
export class PartnerState implements NgxsOnInit {
  @Selector() public static getPartners(state: PartnerStateModel) {
    return state.partners;
  }
  @Selector() public static getPartnersMetadata(state: PartnerStateModel) {
    return state.partnersMetadata;
  }
  @Selector() public static getAllPartners(state: PartnerStateModel) {
    return state.allPartners;
  }
  @Selector() public static getAuthenticatedPartner(state: PartnerStateModel) {
    return state.partnerSelf;
  }
  @Selector() public static selectedPartner(state: PartnerStateModel) {
    return state.selectedPartner;
  }
  @Selector() public static arePartnersLoading(state: PartnerStateModel) {
    return state.arePartnersLoading;
  }
  @Selector() public static areAllPartnersLoading(state: PartnerStateModel) {
    return state.areAllPartnersLoading;
  }
  @Selector() public static getAllInvoices(state: PartnerStateModel) {
    return state.invoicesList;
  }
  @Selector() public static getIsInvoicesLoading(state: PartnerStateModel) {
    return state.isInvoicesLoading;
  }

  @Selector() public static getAllMicrosoftPartners(state: PartnerStateModel) {
    const microsoftPartners = state.allPartners.filter((partner) => partner.externalServices[ExternalServicesKey.Microsoft]);
    return microsoftPartners;
  }

  static getPartnerByLegacyId(partnerLegacyId) {
    return createSelector([PartnerState], (state: PartnerStateModel) => {
      return state.allPartners.find((x) => x.debitorId == partnerLegacyId);
    });
  }

  static getPartnerByUUID(partnerUUID) {
    return createSelector([PartnerState], (state: PartnerStateModel) => {
      return state.allPartners.find((x) => x.id == partnerUUID);
    });
  }

  constructor(
    private partnerService: PartnerService,
    private tenantService: TenantService,
    private toastr: ToastrService,
    private authService: AuthServiceOld,
  ) {}

  ngxsOnInit(ctx: StateContext<PartnerStateModel>) {
    this.authService.localAuthSetup();

    this.authService.Partner.pipe(take(1)).subscribe({
      next: (value) => {
        if (value) {
          ctx.dispatch(new GetPartnersSelf());
        }
      },
      error: (err) => {
        this.toastr.error('Failed to get current partner', 'Error');
      },
    });
  }

  @Action(SelectPartner)
  public selectPartner(ctx: StateContext<PartnerStateModel>, { partner }: SelectPartner) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.selectedPartner = partner;
      }),
    );
  }

  @Action(GetPartners, { cancelUncompleted: true })
  public getPartners(ctx: StateContext<PartnerStateModel>, { filterSettings, isScrolling }: GetPartners) {
    return this.partnerService.getPartners(filterSettings).pipe(
      tap((result: IGetPartner) => {
        const state = produce(ctx.getState(), (draft) => {
          isScrolling ? draft.partners.push(...result.results) : (draft.partners = result.results);

          draft.partnersMetadata = result.metadata;
          draft.arePartnersLoading = false;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(GetAllPartners, { cancelUncompleted: true })
  public async getAllPartners(ctx: StateContext<PartnerStateModel>) {
    const filterSettings: IPartnerFilter = {
      pageIndex: 1,
      pageSize: 250,
      filterPartnerName: '',
    };

    // Initialize state to indicate that partners are loading
    ctx.patchState({
      areAllPartnersLoading: true,
    });

    let partners: IPartner[] = [];
    let partnerData;

    try {
      do {
        const partnerData$ = this.partnerService.getPartners(filterSettings);
        partnerData = await lastValueFrom(partnerData$);

        partners = [...partners, ...partnerData.results];

        filterSettings.pageIndex = filterSettings.pageIndex + 1;
      } while (partners.length < partnerData.metadata.totalRecords);

      const state = produce(ctx.getState(), (draft) => {
        draft.allPartners = partners;
        draft.areAllPartnersLoading = false; // Set loading to false once loading is complete
      });

      ctx.setState(state);
    } catch (error) {
      // Handle error if needed and update the loading state to false.
      console.error('Error while loading partners:', error);

      ctx.patchState({
        areAllPartnersLoading: false,
      });
    }
  }

  @Action(GetPartnersSelf, { cancelUncompleted: true })
  public getPartnerSelf(ctx: StateContext<PartnerStateModel>) {
    return this.partnerService.getPartnerSelf().pipe(
      tap((partner) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.partnerSelf = partner;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetPartner, { cancelUncompleted: true })
  public getPartner(ctx: StateContext<PartnerStateModel>, { partnerId }: GetPartner) {
    return this.partnerService
      .getPartner(partnerId)
      .pipe(
        tap((partner) => {
          const state = produce(ctx.getState(), (draft) => {
            draft.selectedPartner = partner;
          });

          ctx.setState(state);
        }),
        catchError((error: HttpErrorResponse): Observable<any> => {
          this.toastr.error('Unable to get partner data. . ');
          return throwError(error);
        }),
      )
      .subscribe();
  }

  @Action(CreatePartnerAcronisTenant, { cancelUncompleted: true })
  public createPartnerAcronisTenant(ctx: StateContext<PartnerStateModel>, { tenant, partnerId }: CreatePartnerAcronisTenant) {
    return this.tenantService.createPartnerAcronisTenant(tenant, partnerId).pipe(
      tap((tenant) => {
        const state = produce(ctx.getState(), (draft) => {
          const services = draft.selectedPartner.externalServices;
          draft.selectedPartner.externalServices = {
            ...services,
            ...{ ACRONIS: tenant.id },
          };

          const partnersIndex = draft.partners.findIndex((x) => x.debitorId == partnerId);
          const allPartnersIndex = draft.allPartners.findIndex((x) => x.debitorId == partnerId);

          if (partnersIndex !== -1) {
            draft.partners[partnersIndex].externalServices = {
              ...services,
              ...{ ACRONIS: tenant.id },
            };
          }
          if (allPartnersIndex !== -1) {
            draft.partners[allPartnersIndex].externalServices = {
              ...services,
              ...{ ACRONIS: tenant.id },
            };
          }

          if (draft.partnerSelf.debitorId == partnerId)
            draft.partnerSelf.externalServices = {
              ...services,
              ...{ ACRONIS: tenant.id },
            };
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(AttachAcronisTenantIdToPartner, { cancelUncompleted: true })
  public attachAcronisTenantIdToPartner(ctx: StateContext<PartnerStateModel>, { partnerId, tenantId }: AttachAcronisTenantIdToPartner) {
    return this.tenantService.attachAcronisTenantIdToPartner(partnerId, tenantId).pipe(
      tap({
        next: (response) => {},
        error: (error: HttpErrorResponse) => {},
      }),
    );
  }

  @Action(UpdatePartnerLogo, { cancelUncompleted: true })
  public updatePartnerLogo(ctx: StateContext<PartnerStateModel>, { partnerId, logo }: UpdatePartnerLogo) {
    return this.partnerService.updatePartnerLogo(partnerId, logo).pipe(
      tap((result) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedPartner.branding.logo = result;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(GetPartnerLogo, { cancelUncompleted: true })
  public getPartnerLogo(ctx: StateContext<PartnerStateModel>, { partnerId }: GetPartnerLogo) {
    return this.partnerService.getPartnerLogo(partnerId).pipe(
      tap((result) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedPartner.branding.logo = result;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(UpdatePartnerTOS, { cancelUncompleted: true })
  public updatePartnerTOS(ctx: StateContext<PartnerStateModel>, { partnerId, file }: UpdatePartnerTOS) {
    return this.partnerService.updatePartnerTermsOfService(partnerId, file).pipe(
      tap((result) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedPartner.branding.termsOfService = result;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(GetPartnerTOS, { cancelUncompleted: true })
  public getPartnerTOS(ctx: StateContext<PartnerStateModel>, { partnerId }: GetPartnerTOS) {
    return this.partnerService.getPartnerTermsOfService(partnerId).pipe(
      tap((result) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedPartner.branding.termsOfService = result;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(UpdatePartner, { cancelUncompleted: true })
  public updatePartner(ctx: StateContext<PartnerStateModel>, { partnerId, partner }: UpdatePartner) {
    return this.partnerService.updatePartner(partnerId, partner).pipe(
      tap((result) => {
        const state = produce(ctx.getState(), (draft) => {
          const partnersIndex = draft.partners.findIndex((x) => x.id == partnerId);
          const allPartnersIndex = draft.allPartners.findIndex((x) => x.id == partnerId);

          draft.selectedPartner.creditLimit = partner.creditLimit;

          if (partnersIndex != -1) draft.partners[partnersIndex].creditLimit = partner.creditLimit;
          if (allPartnersIndex != -1) draft.partners[allPartnersIndex].creditLimit = partner.creditLimit;

          if (draft.partnerSelf.id == partnerId) draft.partnerSelf.creditLimit = partner.creditLimit;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(error);
      }),
    );
  }

  @Action(GetPartnerInvoices, { cancelUncompleted: true })
  public getPartnerInvoices(ctx: StateContext<PartnerStateModel>, { partnerId }: GetPartnerInvoices) {
    return this.partnerService
      .getInvoices(partnerId)
      .pipe(
        tap((invoices) => {
          const state = produce(ctx.getState(), (draft) => {
            for (let i = 0; i < invoices.invoices.length; i++) {
              if (invoices.invoices[i].remainingAmountIncludingVAT !== 0) {
                if (
                  new Date(invoices.invoices[i].dueDate).getTime() < new Date().getTime() &&
                  new Date(invoices.invoices[i].dueDate).getDate() !== new Date().getDate() &&
                  invoices.invoices[i].type !== 'Credit Memo'
                ) {
                  invoices.invoices[i].isPaid = 'Overdue';
                } else {
                  invoices.invoices[i].isPaid = 'Open';
                }
              } else {
                invoices.invoices[i].isPaid = 'Closed';
              }
            }
            draft.invoicesList = invoices.invoices.sort((a: Invoice, b) => {
              return new Date(b.postingDate).getTime() - new Date(a.postingDate).getTime();
            });
            draft.isInvoicesLoading = false;
          });

          ctx.setState(state);
        }),
        catchError((error: HttpErrorResponse): Observable<any> => {
          this.toastr.error('Unable to get invoices data. . ');
          return throwError(error);
        }),
      )
      .subscribe();
  }

  @Action(ResetPartnerInvoices, { cancelUncompleted: true })
  public resetPartnerInvoices(ctx: StateContext<PartnerStateModel>) {
    const state = produce(ctx.getState(), (draft) => {
      draft.isInvoicesLoading = true;
      draft.invoicesList = [];
    });
    ctx.setState(state);
  }
}
