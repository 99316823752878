import { CreatePartnerTenantModel } from 'src/modules/acronis/models/dtos/createTenantModel';
import { IPartner, IPartnerFilter, IUpdatePartner } from '../models/partner.model';

export class SelectPartner {
  static readonly type = '[Partner] SelectPartner';
  constructor(public partner: IPartner | null) {}
}

export class GetPartners {
  static readonly type = '[Partner] GetPartners';
  constructor(
    public filterSettings: IPartnerFilter,
    public isScrolling: boolean,
  ) {}
}

export class GetAllPartners {
  static readonly type = '[Partner] GetAllPartners';
  constructor() {}
}

export class GetPartnersSelf {
  static readonly type = '[Partner] GetPartnersSelf';
  constructor() {}
}

export class GetPartner {
  static readonly type = '[Partner] GetPartner';
  constructor(public partnerId: IPartner['id']) {}
}

export class CreatePartnerAcronisTenant {
  static readonly type = '[Partner] CreatePartnerAcronisTenant';
  constructor(
    public tenant: CreatePartnerTenantModel,
    public partnerId: IPartner['debitorId'],
  ) {}
}

export class AttachAcronisTenantIdToPartner {
  static readonly type = '[Partner] AttachAcronisTenantIdToPartner';
  constructor(
    public partnerId: IPartner['debitorId'],
    public tenantId: string,
  ) {}
}

export class UpdatePartnerLogo {
  static readonly type = '[Partner] UpdatePartnerLogo';
  constructor(
    public partnerId: IPartner['id'],
    public logo: File,
  ) {}
}

export class GetPartnerLogo {
  static readonly type = '[Partner] GetPartnerLogo';
  constructor(public partnerId: IPartner['id']) {}
}

export class UpdatePartnerTOS {
  static readonly type = '[Partner] UpdatePartnerTOS';
  constructor(
    public partnerId: IPartner['id'],
    public file: File,
  ) {}
}

export class GetPartnerTOS {
  static readonly type = '[Partner] GetPartnerTOS';
  constructor(public partnerId: IPartner['id']) {}
}

export class UpdatePartner {
  static readonly type = '[Partner] UpdatePartner';
  constructor(
    public partnerId: IPartner['id'],
    public partner: IUpdatePartner,
  ) {}
}

export class GetPartnerInvoices {
  static readonly type = '[Partner] GetPartnerInvoices';
  constructor(public partnerId: IPartner['id']) {}
}

export class ResetPartnerInvoices {
  static readonly type = '[Partner] ResetPartnerInvoices';
}
